import React, { FC, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import MetaTags from '../components/MetaTags';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LinkButton from '../components/LinkButton';

import { theme } from '../styles/theme';

import { INDEX_LINK } from '../constants/links';

import { handleScroll } from '../helpers/scrollTop';

const Layout = styled.section`
  display: flex;
  padding: 32px 10%;
  min-height: 100vh;
  min-width: 100%;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 50px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px 0 170px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 90px 0 190px;
  }
`;

const Emoji = styled.div`
  font-size: 62px;
  line-height: 1;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 80px;
  }
`;

const Heading = styled.h2`
  margin-top: 32px;
  font-weight: 900;
  font-size: 22px;
  line-height: 1.64;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.colors.blackText};

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
    line-height: 1;
  }
`;

const Paragraph = styled.p`
  margin: 16px 0 32px;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  font-weight: 300;
  color: ${(props) => props.theme.colors.blackText};
  max-width: 260px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 450px;
  }
`;

const LinkButtonEnhanced = styled(LinkButton)`
  width: 100%;
  max-width: 265px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 350px;
  }
`;

const PageNotFound: FC = () => {
  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MetaTags title='Page not Found' />
      <Header />
      <Layout>
        <Container>
          <Emoji>😳</Emoji>
          <Heading>Page not Found...</Heading>
          <Paragraph>
            Oops! The page you tried to reach doesn&apos;t exist. Please check your link or go back
            to home page.
          </Paragraph>
          <LinkButtonEnhanced href={INDEX_LINK}>back to home page</LinkButtonEnhanced>
        </Container>
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default PageNotFound;
